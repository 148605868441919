import './Menu.css';

export default function Menu() {
  return (
    <div className='menu container text-center mt-4'>
      <h2 className='menuTitle'>CASA DE COMIDAS</h2>
      <a href='https://pency.app/cornisa' className='customMenuButton btn btn-dark col-11 mt-2'>
        <p className='customMenuText mt-2 mb-2'>Carta online</p>
      </a>
      <a href='https://wa.me/message/BOZVK3N4YCEMG1?src=qr' className='customMenuButton btn btn-dark col-11 mt-3'>
        <p className='customMenuText mt-2 mb-2'> Pedi por Whatsapp !</p>
      </a>
      <a href='https://www.instagram.com/Cornisapizzaok' className='customMenuButton btn btn-dark col-11 mt-3'>
        <p className='customMenuText mt-2 mb-2'> Seguinos en instagram !!</p>
      </a>
    </div>
  )
}