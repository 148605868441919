import Header from '../header/Header';
import Menu from '../menu/Menu';

export default function Home() {
  return (
    <div className='home position-fixed top-0 start-0 bottom-0 end-0'>
      <Header />
      <Menu />
    </div>
  )
}