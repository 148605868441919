import './Header.css';

export default function Header() {
  return (
    <header className='container text-center'>
      <div className='row justify-content-center mt-5'>
        <div className='col-12 col-sm-8 col-md-6'>
          <img
            src='https://i.ibb.co/QXTd4X8/logo-fondo-negro.png'
            alt='logo'
            border='0'
            width='25%'
          />
        </div>
      </div>
      <div className='row justify-content-center mt-1'>
        <div className='col-10'>
          <a className='externalLink' href='https://www.instagram.com/cornisapizzaok/' rel="noreferrer" target='_blank'>@Cornisaok</a>
        </div>
      </div>
    </header>
  )
}